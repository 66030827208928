<template>
  <div v-loading="loading">
    <ui-card
      :heading="`${$t('actions.edit')} ${$tc('models.building')}`"
      class="mb-8"
    >
      <building-form
        v-if="building"
        :building="building"
        @submit="handleSubmit"
        @cancel="handleCancel"
      ></building-form>
    </ui-card>

    <ui-card
      :heading="`${$t('actions.delete')} ${$tc('models.building')}`"
      v-if="building"
    >
      <ui-link
        type="danger"
        :disabled="
          building.departmentNumber !== 0 || building.courseNumber !== 0
        "
        @click="handleDelete"
        >{{ `${$t('actions.delete')} ${$tc('models.building')}` }}</ui-link
      >
      <div v-if="building.departmentNumber !== 0">
        {{ $t('misc.cannot-delete-reason') }} {{ building.departmentNumber }}
        {{ $tc('models.department', 2) }}
      </div>
      <div v-if="building.courseNumber !== 0">
        {{ $t('misc.cannot-delete-reason') }} {{ building.courseNumber }}
        {{ $tc('models.course', 2) }}
      </div>
    </ui-card>

    <ui-modal
      :show="showDeleteModal"
      :text="$t('actions.confirm_delete', { model: $tc('models.building') })"
      :confirmText="$t('actions.delete')"
      @confirm="deleteBuilding"
      @cancel="showDeleteModal = false"
    >
    </ui-modal>
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiLink from '@/components/ui/UiLink';
import UiModal from '@/components/ui/UiModal';
import BuildingForm from '../components/BuildingForm';
import { getBuilding, updateBuilding, deleteBuilding } from '../api';

export default {
  data() {
    return {
      building: null,
      loading: false,
      showDeleteModal: false
    };
  },

  components: {
    UiCard,
    UiLink,
    UiModal,
    BuildingForm
  },

  methods: {
    async getBuilding() {
      this.loading = true;
      const building = await getBuilding(this.$route.params.id);
      this.building = building;
      this.loading = false;
    },

    async handleSubmit() {
      this.loading = true;
      await updateBuilding(this.building);
      this.$router.back();
      this.loading = false;
    },

    handleDelete() {
      this.showDeleteModal = true;
    },

    async deleteBuilding() {
      this.loading = true;
      await deleteBuilding(this.building.id);
      this.showDeleteModal = false;
      this.$router.back();
      this.loading = true;
    },

    handleCancel() {
      this.$router.back();
    }
  },

  created() {
    this.getBuilding();
  },

  async mounted() {
    let buildingId = this.$route.params.id;
    let building = await getBuilding(buildingId);
    let buildingName = building.name;
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.buildings", link: "/app/buildings/"},
      {name: buildingName, link: "/app/buildings/" + buildingId},
      {name: "actions.edit", link: ""}
    ]);
  },
};
</script>
